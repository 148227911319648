



















import { Component } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import SettingsBoxItem from '@/views/settings/SettingsBoxItem.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import ChangeTimeZoneModalComponent
  from '@/components/settings/site-preferences/ChangeTimeZoneModalComponent.vue';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

@Component({
  components: {
    ChangeTimeZoneModalComponent,
    SettingsBoxItem,
  },
})
export default class SitePreferencesSection extends BreakpointWrapper {
  @State
  selectedTzName!: string;

  @State
  communityTzName!: string;

  @State
  localTzName!: string;

  @Getter
  protected readonly authUser!: CommunityUser;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  private get isNotForcedEventTimeZone(): boolean {
    return !this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE)
      || !this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE).enabled;
  }

  private get defaultTimezone(): number {
    return this.selectedTzName === this.communityTzName ? 1 : 2;
  }

  openTimeZoneEditModal(): void {
    this.$bvModal.show('change-time-zone-modal');
  }
}
